// Login
export const LOGIN = 'cashiers/login';
export const LOGOUT = 'cashiers/logout';
export const PUT_CASHIER_PASSWORD = 'cashiers/password';
export const PUT_ACTIVE_PARTNER = (partnerId: number): string => `cashiers/active-partner/${partnerId}`;
export const GET_SEARCH = 'cashiers/search';
export const ALL_COUNTRIES_GET = 'cashiers/countries/all';

// Branding
export const GET_CASHIERS_BRANDING = 'public/cashiers/branding';
export const GET_CASHIERS_SETTINGS_BRANDING = 'cashiers/branding';
export const PUT_CASHIERS_BRANDING = (id: number): string => `cashiers/branding/${id}`;

// Dashboard
export const CREATE_ACTIVITY_CARDS_GET = (code: string): string => `cashiers/dashboard/main?currencyCode=${code}`;
export const CREATE_ACTIVITY_CARDS_PERIODICAL_GET = (code: string): string =>
  `cashiers/dashboard/main/periodical?currencyCode=${code}`;

// Cashier
export const GET_CASHIER_PARTNER_ADMIN = 'cashiers/users/partner-admin';
export const GET_CASHIER_BET_SHOP_ADMIN = 'cashiers/users/betshop-admin';
export const GET_CASHIER_POS_ADMIN = 'cashiers/users/pos-admin';
export const GET_CASHIERS = 'cashiers/users/cashier';
export const POST_CASHIER = 'cashiers/users/cashier';
export const GET_USER_SINGLE = (id: number): string => `cashiers/users/info/${id}`;
export const PUT_CASHIER = (id: number): string => `cashiers/users/cashier/${id}`;
export const PUT_CHANGE_USER_PASSWORD_PUT = (id: number): string => `cashiers/password/sub/${id}`;
export const GET_CASHIER_DASHBOARD_CARDS = (id: string, code: string): string =>
  `cashiers/dashboard/cashier/${id}?currencyCode=${code}`;
export const GET_BET_SHOP_ADMIN_DASHBOARD_CARDS = (id: string, code: string): string =>
  `cashiers/dashboard/bet-shop-admin/${id}?currencyCode=${code}`;
export const GET_POS_ADMIN_DASHBOARD_CARDS = (id: string, code: string): string =>
  `cashiers/dashboard/pos-admin/${id}?currencyCode=${code}`;
export const GET_POS_ADMIN_CASHIERS = (id: string): string => `cashiers/subs/${id}`;

// Partner
export const GET_PARTNERS = 'cashiers/partners';
export const GET_PARTNER = (id: number): string => `cashiers/partners/${id}`;
export const POST_PARTNER = 'cashiers/partners';
export const PUT_PARTNER = (id: number): string => `cashiers/partners/${id}`;

// Currency
export const GET_CASHIERS_SYSTEM_CURRENCIES = 'cashiers/system/currencies';
export const GET_CASHIERS_PARTNER_CURRENCIES = 'cashiers/partner/currencies';
export const POST_CASHIERS_CURRENCY = 'cashiers/currency';
export const PUT_CASHIERS_CURRENCY = (id: number): string => `cashiers/currency/${id}`;

// BetShop
export const GET_BET_SHOPS = 'cashiers/bet-shops';
export const GET_BET_SHOP = (id: number): string => `cashiers/bet-shops/${id}`;
export const POST_BET_SHOP = 'cashiers/bet-shops';
export const PUT_BET_SHOP = (id: number): string => `cashiers/bet-shops/${id}`;
export const GET_BET_SHOP_DASHBOARD_CARDS = (shopId: string, code: string): string =>
  `cashiers/dashboard/bet-shop/${shopId}?currencyCode=${code}`;

// Info properties
export const GET_BET_SHOPS_INFO = 'cashiers/info-properties/bet-shop';
export const POST_BET_SHOP_INFO = 'cashiers/info-property/bet-shop';
export const PUT_BET_SHOP_INFO = (id: number): string => `cashiers/info-property/bet-shop/${id}`;
export const DELETE_BET_SHOP_INFO = (id: number): string => `cashiers/info-property/bet-shop/${id}`;

export const GET_POS_INFO = 'cashiers/info-properties/pos';
export const POST_POS_INFO = 'cashiers/info-property/pos';
export const PUT_POS_INFO = (id: number): string => `cashiers/info-property/pos/${id}`;
export const DELETE_POS_INFO = (id: number): string => `cashiers/info-property/pos/${id}`;

export const GET_PAYMENT_METHOD_INFO = (methodId: number): string => `cashiers/info-properties/payment/${methodId}`;
export const POST_PAYMENT_METHOD_INFO = 'cashiers/info-property/payment';
export const PUT_PAYMENT_METHOD_INFO = (id: number): string => `cashiers/info-property/payment/${id}`;
export const DELETE_PAYMENT_METHOD_INFO = (id: number): string => `cashiers/info-property/payment/${id}`;

// POS
export const GET_POS = 'cashiers/poses';
export const GET_POS_TRANSFER = 'cashiers/poses/transfer';
export const POST_POS = 'cashiers/poses';
export const GET_POS_SINGLE = (id: number): string => `cashiers/poses/${id}`;
export const PUT_POS = (id: number): string => `cashiers/poses/${id}`;
export const GET_POS_DASHBOARD_CARDS = (shopId: string, code: string): string =>
  `cashiers/dashboard/pos/${shopId}?currencyCode=${code}`;

// POS
export const POST_WALLET = (shopId: number): string => `cashiers/wallets/${shopId}`;
export const PUT_POS_TRANSFER = (walletId: number): string => `cashiers/pos/transfer/${walletId}`;
export const PUT_BET_SHOP_TRANSFER = (walletId: number): string => `cashiers/bet-shop/transfer/${walletId}`;

// Wallet
export const GET_SHOP_WALLET = (shopId: number | string): string => `cashiers/wallets/${shopId}`;
export const PUT_TRANSFER_BET_SHOP_MONEY = (walletId: number | string): string =>
  `cashiers/bet-shop/transfer/${walletId}`;
export const PUT_TRANSFER_POS_MONEY = (walletId: number | string): string => `cashiers/pos/transfer/${walletId}`;
export const GET_PLAYER_TRANSFER_DEPOSIT = (playerId: number): string => `cashiers/player/${playerId}/info`;
export const GET_PLAYER_TRANSFER_WITHDRAW = (playerId: number, code: string): string =>
  `cashiers/withdraw/ticket/info?playerId=${playerId}&code=${code}`;
export const POST_PLAYER_TRANSFER_DEPOSIT = `cashiers/deposit`;
export const POST_PLAYER_TRANSFER_WITHDRAW = `cashiers/withdraw`;
export const POST_TRANSFER_AMOUNT = (walletId: number | string): string => `cashiers/commission/transfer/${walletId}`;
export const POS_TO_POS = 'cashiers/pos-to-pos/transfer';

// Payment
export const GET_IN_REVIEW_PAYMENTS = 'cashiers/tickets/in-review';
export const GET_COMPLETED_PAYMENTS = 'cashiers/tickets/final';
export const APPROVE_PAYMENT = (ticketId: number): string => `cashiers/tickets/approve/${ticketId}`;
export const REJECT_PAYMENT = (ticketId: number): string => `cashiers/tickets/reject/${ticketId}`;

// Payment methods system
export const GET_SYSTEM_PAYMENT_METHODS = 'cashiers/system-payment-methods';
export const POST_SYSTEM_PAYMENT_METHOD = 'cashiers/system-payment-methods';
export const GET_SINGLE_SYSTEM_PAYMENT_METHODS = (id: number): string => `cashiers/system-payment-methods/${id}`;
export const PUT_SYSTEM_PAYMENT_METHODS = (id: number): string => `cashiers/system-payment-methods/${id}`;
export const DELETE_SYSTEM_PAYMENT_METHODS = (id: number): string => `cashiers/system-payment-methods/${id}`;

// Payment methods partner
export const GET_PARTNER_PAYMENT_METHOD_BY_ID = (id: number): string => `cashiers/partner-payment-methods/method/${id}`;
export const GET_PARTNER_PAYMENT_METHODS = 'cashiers/partner-payment-methods';
export const GET_POS_PARTNER_PAYMENT_METHODS = (shopId: number): string =>
  `cashiers/partner-payment-methods/shop/${shopId}`;
export const GET_PARTNER_ENABLED_PAYMENT_METHODS = 'cashiers/partner-payment-methods/enabled';
export const GET_PARTNER_PAYMENT_METHODS_BY_PARTNER = (partnerId: number): string =>
  `cashiers/partner-payment-methods/${partnerId}`;
export const POST_PARTNER_PAYMENT_METHOD = (partnerId: number): string =>
  `cashiers/partner-payment-methods/${partnerId}`;
export const GET_SINGLE_PARTNER_PAYMENT_METHODS = (id: number): string => `cashiers/partner-payment-methods/${id}`;
export const PUT_PARTNER_PAYMENT_METHODS = (id: number): string => `cashiers/partner-payment-methods/${id}`;
export const DELETE_PARTNER_PAYMENT_METHODS = (id: number): string => `cashiers/partner-payment-methods/${id}`;

// Payment credentials
export const GET_PAYMENT_METHODS_CREDENTIALS = (shopId: number | string): string =>
  `cashiers/partner-payment-credentials/${shopId}/methods`;
export const POST_PAYMENT_METHODS_CREDENTIALS = 'cashiers/partner-payment-credentials';
export const GET_SINGLE_PAYMENT_METHODS_CREDENTIALS = (id: number): string =>
  `cashiers/partner-payment-credentials/${id}`;
export const PUT_PAYMENT_METHODS_CREDENTIALS = `cashiers/partner-payment-credentials`;
export const DELETE_PAYMENT_METHODS_CREDENTIALS = (id: number): string => `cashiers/partner-payment-credentials/${id}`;

// Payment method info properties
export const GET_PAYMENT_METHOD_INFO_PROPERTIES = (id: number): string => `cashiers/info-properties/payment/${id}`;
export const POST_PAYMENT_METHOD_INFO_PROPERTY = 'cashiers/info-property/payment';
export const PUT_PAYMENT_METHOD_INFO_PROPERTY = (id: number): string => `cashiers/info-property/payment/${id}`;
export const DELETE_PAYMENT_METHOD_INFO_PROPERTY = (id: number): string => `cashiers/partner-payment-methods/${id}`;

// Reports
export const GET_REPORTS_BY_BET_SHOP = 'cashiers/report/bet-shop';
export const GET_REPORTS_BY_POS = 'cashiers/report/pos';
export const GET_REPORTS_BY_CASHIER = 'cashiers/report/user';

// Single Entity transactions TODO
export const CREATE_USERS_BETSHOPS_GET = (id: number): string => `cashiers/bet-shops/user/${id}`;
export const CREATE_USERS_POSES_GET = (id: number): string => `cashiers/poses/user/${id}`;
export const CREATE_USERS_INTERNAL_GET = (id: number): string => `cashiers/internal-transactions/cashier/${id}`;
export const CREATE_USERS_EXTERNAL_GET = (id: number): string => `cashiers/external-transactions/cashier/${id}`;
export const GET_PLAYER_TRANSACTIONS_BET = (id: string): string => `${id}`;

// Betshops
export const GET_BET_SHOPS_POSES = (id: number): string => `cashiers/poses/bet-shop/${id}`;
export const GET_BET_SHOPS_FROM_PARENT = (id: number): string =>
  `cashiers/internal-transactions/shop/from-parent/${id}`;
export const GET_BET_SHOPS_TO_SUB = (id: number): string => `cashiers/internal-transactions/shop/to-sub/${id}`;

//POSes
export const GET_POSES_CASHIERS = (id: number): string => `cashiers/users/pos/${id}`;
export const GET_TRANSACTIONS_FROM_BET_SHOPS = (id: number): string => `cashiers/internal-transactions/pos/${id}`;
export const GET_TRANSACTIONS_TO_PLAYERS = (id: number): string => `cashiers/external-transactions/shop/${id}`;
export const GET_DAILY_REPORTS = (id: string): string => `cashiers/report/shop/commission/${id}`;

//Voucher
export const POST_CREATE_VOUCHER = 'cashiers/voucher';
export const GET_PENDING_VOUCHERS = 'cashiers/voucher/pending';
export const GET_USED_VOUCHERS = 'cashiers/voucher/final';

//Branding
export const PUT_BRANDING = (id: number): string => `cashiers/branding/${id}`;

//Penalty
export const PUT_TRANSFER_AMOUNT = (id: number): string => `cashiers/penalty/${id}`;
