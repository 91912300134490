import { FC, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { CashierPermissionsEnum } from 'enums/accessTemplates';

import TransferIcon from 'components/common/icons/bulk/Transfer';
import AddIcon from 'components/common/icons/bulk/AddIcon';
import { AccessCreatePermissions, AccessTransferPermissions } from 'utils/accessTemplates';
import VoucherIcon from 'components/common/icons/bulk/Voucher';
import PosTransferIcon from 'components/common/icons/bulk/PosTransfer';

import CreateModal from '../MainModals/CreateModal';
import TransferModal from '../MainModals/TransferModal';
import TransferPlayerModal from '../MainModals/TransferPlayerModal';
import VoucherModal from '../MainModals/VoucherModal';
import PosToPosModal from '../MainModals/PosToPosModal';

type Props = {
  permission: CashierPermissionsEnum;
};

const PopupMenu: FC<Props> = ({ permission }) => {
  const [createOpen, setCreateOpen] = useState(false);
  const [transferOpen, setTransferOpen] = useState(false);
  const [voucherOpen, setVoucherOpen] = useState(false);
  const [posToPosOpen, setPosToPosOpen] = useState(false);

  const openCreatePopup = (): void => setCreateOpen(true);
  const openTransferPopup = (): void => setTransferOpen(true);
  const openVoucherPopup = (): void => setVoucherOpen(true);
  const openPosToPosPopup = (): void => setPosToPosOpen(true);

  return (
    <>
      <Stack direction="row" spacing={0.5} mx={1}>
        {AccessCreatePermissions.some((r) => r === permission) && (
          <IconButton onClick={openCreatePopup}>
            <AddIcon />
          </IconButton>
        )}
        {AccessTransferPermissions.some((r) => r === permission) && (
          <IconButton onClick={openTransferPopup}>
            <TransferIcon />
          </IconButton>
        )}
        {(CashierPermissionsEnum.USER === permission || permission === CashierPermissionsEnum.POS_ADMIN) && (
          <IconButton onClick={openVoucherPopup}>
            <VoucherIcon />
          </IconButton>
        )}
        {CashierPermissionsEnum.POS_ADMIN === permission && (
          <IconButton onClick={openPosToPosPopup}>
            <PosTransferIcon />
          </IconButton>
        )}
      </Stack>
      {createOpen && <CreateModal permission={permission} onClose={(): void => setCreateOpen(false)} />}
      {transferOpen &&
        permission !== CashierPermissionsEnum.USER &&
        permission !== CashierPermissionsEnum.POS_ADMIN && (
          <TransferModal onClose={(): void => setTransferOpen(false)} />
        )}
      {transferOpen &&
        (permission === CashierPermissionsEnum.USER || permission === CashierPermissionsEnum.POS_ADMIN) && (
          <TransferPlayerModal permission={permission} onClose={(): void => setTransferOpen(false)} />
        )}
      {voucherOpen &&
        (permission === CashierPermissionsEnum.USER || permission === CashierPermissionsEnum.POS_ADMIN) && (
          <VoucherModal permission={permission} onClose={(): void => setVoucherOpen(false)} />
        )}
      {posToPosOpen && permission === CashierPermissionsEnum.POS_ADMIN && (
        <PosToPosModal onClose={(): void => setPosToPosOpen(false)} />
      )}
    </>
  );
};

export default PopupMenu;
