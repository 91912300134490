export enum EntityEnum {
  SHOP = 'shop',
  USER = 'user',
}

export enum EntityStatus {
  ACTIVE = 'ACTIVE',
  BLOCK = 'BLOCK',
}

export enum EntityStatusText {
  ACTIVE = 'Active',
  BLOCK = 'Blocked',
}

export enum TransferTypeEnum {
  DEPOSIT_COMMISSION = 'DEPOSIT_COMMISSION',
  WITHDRAW_COMMISSION = 'WITHDRAW_COMMISSION',
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  BOTH = 'BOTH',
  TRANSFER_BETWEEN_POSES = 'TRANSFER_BETWEEN_POSES',
  PENALTY = 'PENALTY',
}

export enum TransferTypeTextEnum {
  DEPOSIT_COMMISSION = 'Deposit Commission',
  WITHDRAW_COMMISSION = 'Withdraw Commission',
  DEPOSIT = 'Deposit',
  WITHDRAW = 'Withdraw',
  BOTH = 'Both',
  TRANSFER_BETWEEN_POSES = 'Transfer between POSes',
  PENALTY = 'Penalty',
}

export enum PaymentMethodTypeEnum {
  MANUAL = 'MANUAL',
  AUTOMATED = 'AUTOMATED',
}
export enum PaymentMethodTypeTextEnum {
  MANUAL = 'Manual',
  AUTOMATED = 'Automated',
}

export enum TrendingActivityEnum {
  UP = 'up',
  DOWN = 'down',
}

export enum FooterNavigationEnum {
  TRANSFER = 'transfer',
  CREATE = 'create',
  NOTIFICATION = 'notification',
  PROFILE = 'profile',
  POS_TO_POS = 'posToPos',
}

export enum FilterButtonStyleEnum {
  ICON = 'icon',
  BUTTON = 'button',
}

export enum FooterSelectMenuTypeEnum {
  PARTNER = 'Partner',
}

export enum CashierShopTypeEnum {
  BET_SHOP = 'BET_SHOP',
  POS = 'POS',
  PAYMENT = 'PAYMENT',
}

export enum PaymentPageType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
}
