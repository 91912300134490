import { FC, useState, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { Stack, Box, SelectChangeEvent } from '@mui/material';

import useToaster from 'hooks/useToaster';
import Modal from 'components/common/Modal';
import Form, { FormNumberInput, FormSelect, Autocomplete } from 'components/common/Form';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { transferMoneySchemaPosToPos } from 'configs/validationSchemes';
import { translate, TranslationKey } from 'utils/translate';
import { ITransferMoney, WalletType } from 'types/transfer';
import { posToPosTransfer } from 'api/requests/transfer';
import { OptionType } from 'types/utils';
import { ApiErrorType, IShopListDTO } from 'types';
import useScreen from 'hooks/useScreen';
import BalanceGroup from 'components/common/BalanceGroup';
import { getPos, getShopWallets } from 'api/requests/shops';
import useMount from 'hooks/useMount';
import PosTransferIcon from 'components/common/icons/bulk/PosTransfer';

interface Props {
  onClose: () => void;
}

const PosToPosModal: FC<Props> = ({ onClose }) => {
  const { isMobile } = useScreen();
  const [isLoading, setIsLoading] = useState(false);
  const [poses, setPoses] = useState<IShopListDTO[]>([]);
  const [currencyOptions, setCurrencyOptions] = useState<WalletType[]>([]);
  const [FromPosWallet, setFromPosWallet] = useState<WalletType | null>(null);
  const [betShopWalletOptions, setBetShopWalletOptions] = useState<WalletType[]>([]);
  const [ToPosWallet, setToPosWallet] = useState<WalletType | null>(null);
  const notify = useToaster();

  interface ITransfer extends Omit<ITransferMoney, 'fromPosWalletId' | 'toPosWalletId'> {
    fromPosWalletId: string;
    toPosWalletId: string;
  }

  const resolver = useYupValidationResolver<Partial<ITransfer>>(
    transferMoneySchemaPosToPos as unknown as ObjectSchema<Partial<ITransfer>>,
  );
  const { control, watch, trigger, setValue, handleSubmit } = useForm<Partial<ITransfer>>({
    resolver,
  });

  const fromPos = watch('fromPosWalletId');
  const toPos = watch('toPosWalletId');

  const selectedFromPos = useMemo(() => {
    return poses.find(({ id }) => String(id) === fromPos);
  }, [fromPos, poses]);

  const selectedToPos = useMemo(() => {
    return poses.find(({ id }) => String(id) === toPos);
  }, [toPos, poses]);

  useMount(() => {
    (async (): Promise<void> => {
      try {
        const res = await getPos({ page: '1', size: String(Number.MAX_SAFE_INTEGER) });
        setPoses(res.data.data);
      } catch (error) {
        console.error(error);
      }
    })();
  });

  const handleCreateSubmit = async (data: Partial<ITransfer>): Promise<void> => {
    setIsLoading(true);
    try {
      await posToPosTransfer({
        fromPosWalletId: FromPosWallet?.id as number,
        toPosWalletId: ToPosWallet?.id as number,
        amount: data.amount as number,
      });
      setIsLoading(false);
      notify({
        message: 'Success',
        type: 'success',
      });
      onClose();
    } catch (error) {
      setIsLoading(false);
      notify({
        message: ((error as unknown as ApiErrorType)?.text as TranslationKey) || translate('Something went wrong'),
        type: 'error',
      });
    }
  };

  const getShopCurrencies = async (id: string): Promise<void> => {
    try {
      const response = await getShopWallets(id);
      const wallets = response.data ?? [];

      const options = [
        ...(wallets?.reduce(
          (acc, wallet) => {
            if (betShopWalletOptions.find(({ currencyCode }) => currencyCode === wallet.currencyCode)) {
              acc.push({
                id: wallet.currencyCode,
                name: wallet.currencyCode,
                balance: wallet.balance,
                currencyCode: wallet.currencyCode,
              });
            }

            return acc;
          },
          [] as { id: string; name: string; balance: number; currencyCode: string }[],
        ) || []),
      ];

      setCurrencyOptions(options as unknown as WalletType[]);

      setValue('walletId', (options[0]?.currencyCode || '') as string);
      setToPosWallet(
        wallets.find(({ currencyCode }) => currencyCode === (options[0] as unknown as WalletType).currencyCode) || null,
      );
      setFromPosWallet(
        betShopWalletOptions.find(({ currencyCode }) => currencyCode === options[0]?.currencyCode) as WalletType,
      );
      await trigger('walletId');
    } catch (error) {
      console.error(error);
    }
  };

  const handleFromPosChange = async (id: string): Promise<void> => {
    const response = await getShopWallets(id);
    const wallets = response.data ?? [];
    setBetShopWalletOptions(wallets);
    setFromPosWallet(wallets[0] || null);
    setValue('toPosWalletId', undefined);
    setValue('walletId', undefined);
  };

  const handlePosChange = async (id: string): Promise<void> => {
    await getShopCurrencies(id);
  };

  const onSave = async (): Promise<void> => {
    handleSubmit(handleCreateSubmit)();
  };

  const handleChangeCurrencyCode = useCallback(
    (event: SelectChangeEvent<unknown>): void => {
      const selectedWallet = currencyOptions.find((i) => i.id === event.target.value);
      if (ToPosWallet) {
        setToPosWallet(selectedWallet || null);
        const selectedBetShopWallet: WalletType | undefined = betShopWalletOptions.find(
          (i) => i.currencyCode === selectedWallet?.currencyCode,
        );
        setFromPosWallet(selectedBetShopWallet || null);
      } else {
        setFromPosWallet(selectedWallet || null);
      }
    },
    [currencyOptions, ToPosWallet, betShopWalletOptions],
  );

  return (
    <Modal
      maxWidth={544}
      fullScreen={isMobile}
      saveText="Apply"
      icon={<PosTransferIcon />}
      title="Money Transfer"
      isLoading={isLoading}
      onClose={onClose}
      onSave={handleSubmit(onSave)}
    >
      <Box>
        <Stack spacing={2}>
          <Form onSubmit={handleSubmit(handleCreateSubmit)}>
            <Stack spacing={2}>
              <Autocomplete
                required
                options={poses}
                name="fromPosWalletId"
                control={control}
                displayProp="name"
                label="From Pos"
                onChange={handleFromPosChange}
              />
              <Autocomplete
                required
                options={poses.filter(({ id }) => id !== Number(fromPos))}
                name="toPosWalletId"
                control={control}
                displayProp="name"
                label="POS"
                onChange={handlePosChange}
              />
              <FormSelect
                required
                control={control}
                name="walletId"
                label="Currency"
                onChange={handleChangeCurrencyCode}
                options={currencyOptions as unknown as OptionType[]}
              />
              <FormNumberInput required control={control} name="amount" label="Amount" />
            </Stack>
          </Form>
          <BalanceGroup
            betShop={selectedFromPos}
            pos={selectedToPos}
            betShopWallet={FromPosWallet}
            posWallet={ToPosWallet}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

export default PosToPosModal;
